
import { defineComponent} from "vue";
import ProgressSpinner from "primevue/progressspinner";

const __default__ = defineComponent({
  name: "Spinner",
  components: {
    ProgressSpinner,
  },
  props: {
    text: String,
    color: String,
    textColor: String,
  }
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "e1018d60": (_ctx.color),
  "2a5279a3": (_ctx.textColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__